.contact {
  min-height: auto;
  padding-bottom: 7rem;
}

.contact p{
  color: var(--text-color);
  font-size: larger;
  margin-left: 225px;
}

.contact h2 {
  margin-bottom: 30px;
}

.contact form {
  max-width: 50rem;
  margin: 1rem auto;
  text-align: center;
  margin-bottom: 2rem;
}

.contact form .input-box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.contact form .input-box input,
.contact form textarea {
  width: 100%;
  padding: 15px;
  font-size: 16px;
  color: var(--text-color);
  background: var(--second-bg-color);
  border-radius: .8rem;
  margin: .5rem 0;
}

.contact form .input-box input {
  width: 49%;
}

.contact form textarea {
  resize: none;
  height: 150px;
}

.contact form .btn {
  margin-top: 2rem;
  cursor: pointer;
}

@media (max-width: 450px) {
  .contact form .input-box input {
      width: 100%;
  }
  .contact form .btn {
    margin-top: 2rem;
    cursor: pointer;
    margin-right: 20px;
  }

}

@media(max-width:1050px){
  .contact p{
    margin-left: 50px;
  }
  .contact form .btn {
    margin-top: 2rem;
    cursor: pointer;
    margin-right: 70px;
  }
}