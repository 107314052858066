.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px 9%;
  background: var(--second-bg-color);
}

.footer-text p {
  font-size: 17px;
}

.footer-iconTop a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background: var(--main-color);
  border-radius: .8rem;
  transition: .5s ease;
}

.footer-iconTop a:hover {
  box-shadow: 0 0 15px var(--main-color-hover);
}

.footer-iconTop a i {
  font-size: 24px;
  color: var(--second-bg-color);
}