@import url('https://fonts.googleapis.com/css2?family=Neonderthaw&display=swap');


.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 9%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  background-color: var(--bg-color);
}

.logo {
  font-family: 'Neonderthaw';
  font-size: 35px;
  background: 
  var(--main-gradient-color);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  font-weight: 600;
}

.navbar a {
  font-size: 18px;
  color: var(--text-color);
  margin-left: 30px;
  transition: .3s;
}
.navbar-menu a:hover,
.navbar-menu a:active,
.navbar a:hover,
.navbar a.active {
  color: var(--main-color);
}

.navbar-menu_container p {
  color: var(--text-color);
  font-weight: 500;
  font-size: 15px;
  line-height: 25px;
  text-transform: capitalize;
  
  cursor: pointer;
}

.navbar-menu {
  margin-left: 1rem;
  display: none;
  position: relative;
}

.navbar-menu svg {
  cursor: pointer;
}

.navbar-menu_container {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  text-align: center;
  background: var(--second-bg-color);
  padding-top: 30px;
  padding-bottom:30px ;
  position: absolute;
  right: 0;
  top: 40px;
  margin-top: 1rem;
  min-width: 180px;
  border-radius: 15px;
  box-shadow: 0px 0px 5px rgba(0,0, 0,0.2);
}

.navbar-menu_container p {
  margin: 1rem 0;
}

.navbar-menu a{
  font-size: 14px;
  transition: .3s;
  color: var(--text-color);
}

@media (max-width: 1350px) {
  .navbar a {
    font-size: 16px;
  }
  .logo {
    font-size: 23px;
  }
}

@media (max-width: 1250px) {
  .navbar a {
    font-size: 15px;
  }
}

@media (max-width: 1150px) {
  .navbar a {
    font-size: 14px;
  }
  .logo {
    font-size: 20px;
  }
}

@media (max-width: 788px) {
  .navbar-menu {
    display: block;
  }
}

@media (max-width: 788px) {
  .navbar {
    display: none;
}
}

@media screen and (max-width: 550px) {
  .navbar-menu_container {
    top: 20px;
    min-width: 150px;
    padding-top: 15px;
  padding-bottom:15px ;
}
.navbar-menu_container a {
  font-size: smaller;
}
}