:root {
  --bg-color: #f0f0f0;
  --second-bg-color: #d5d5d5;
  --text-color: #000000;
  --second-text-color: #dadada ;
  --main-color: #1b1b1b;
  --main-color-hover: #717171;
  --main-gradient-color: linear-gradient(90deg, rgb(91, 91, 91) 0%, rgb(120, 120, 120) 100%);
  --main-gradient-color-hover: linear-gradient(90deg, rgb(46, 46, 46) 0%, rgb(126, 126, 126) 100%);
  /* -webkit-background-clip: text;
-webkit-text-fill-color: transparent; */
}

[data-theme="dark"] {
  --bg-color: #202020;
  --second-bg-color: #434343;
  --text-color: #ffffff;
  --second-text-color: #232323;
  --main-color: #c2c2c2;
  --main-color-hover: #7e7e7e;
  --main-gradient-color: linear-gradient(90deg, rgb(145, 145, 145) 0%, rgb(216, 216, 216) 100%);
  --main-gradient-color-hover: linear-gradient(90deg, rgb(197, 197, 197) 0%, rgb(173, 173, 173) 100%);
}

[data-theme="light"] {
  --bg-color: #f0f0f0;
  --second-bg-color: #b6b6b6;
  --text-color: #000000;
  --second-text-color: #dadada;
  --main-color: #1b1b1b;
  --main-color-hover: #717171;
  --main-gradient-color: linear-gradient(90deg, rgb(91, 91, 91) 0%, rgb(120, 120, 120) 100%);
  --main-gradient-color-hover: linear-gradient(90deg, rgb(46, 46, 46) 0%, rgb(126, 126, 126) 100%);
}

.Light_theme {
  --bg-color: #f0f0f0;
  --second-bg-color: #808080;
  --text-color: #000000;
  --second-text-color: #dadada;
  --main-color: #1b1b1b;
  --main-color-hover: #717171;
  --main-gradient-color: linear-gradient(90deg, rgb(91, 91, 91) 0%, rgb(120, 120, 120) 100%);
  --main-gradient-color-hover: linear-gradient(90deg, rgb(46, 46, 46) 0%, rgb(126, 126, 126) 100%);
  /* -webkit-background-clip: text;
-webkit-text-fill-color: transparent; */
}

.Dark_theme {
  --bg-color: #202020;
  --second-bg-color: #434343;
  --text-color: #ffffff;
  --second-text-color: #232323;
  --main-color: #c2c2c2;
  --main-color-hover: #7e7e7e;
  --main-gradient-color: linear-gradient(90deg, rgb(145, 145, 145) 0%, rgb(216, 216, 216) 100%);
  --main-gradient-color-hover: linear-gradient(90deg, rgb(197, 197, 197) 0%, rgb(173, 173, 173) 100%);
  /* -webkit-background-clip: text;
-webkit-text-fill-color: transparent; */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar{
   width: 15px;
}

::-webkit-scrollbar-track{
  background-color:var(--bg-color);
}

::-webkit-scrollbar-thumb{
  background: var(--main-gradient-color);
  border-radius: 15px;
}

::-webkit-scrollbar-thumb:hover{
  background: var(--main-gradient-color-hover);
  border-radius: 15px;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

