.Projects {
  background: var(--second-bg-color);
  min-height: auto;
  padding-bottom: 10rem;
}

.Projects h2 {
  margin-bottom: 4rem;
}

.Projects-Category{
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 25px;
}

.line{
  width: calc(100% ); /* Set the width to 100% of the screen width minus 20 pixels */
  height: 5px; /* Set the height of the line */
  background-color: var(--bg-color); /* Replace "yourColor" with the desired color code or name */
  margin: 0 auto; /* Center the line horizontally */
  margin-top: 50px;
}

.Projects-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  gap: 2.5rem;
}

.Projects-container .Projects-box {
  position: relative;
  border-radius: 2rem;
  box-shadow: 0 0 2rem var(--bg-color);
  overflow: hidden;
  display: flex;
}

.Projects-box img {
  width: 100%;
  max-height: 250px;
  transition: .5s ease;
}

.Projects-box:hover img {
  transform: scale(1.1);
}

.l{
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  margin-bottom: 15px;
}

.Projects-layer img{
  width: 30px;
}

.Projects-box .Projects-layer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.6), var(--bg-color));
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 0 4rem;
  transform: translateY(100%);
  transition: .5s ease;
}

.Projects-box:hover .Projects-layer {
  transform: translateY(0);
}

.Projects-layer h4 {
  font-size: 20px;
  background: linear-gradient(90deg, rgb(149, 1, 255) 0%, rgb(63, 37, 237) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.Projects-layer p {
  font-size: 13px;
  margin: .3rem 0 0.5rem;
}

.Projects-layer a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: var(--main-gradient-color);
  border-radius: 50%;
}

.Projects-layer a i {
  font-size: 20px;
  color: white;
}

@media (max-width: 1179px) {
  .Projects-container {
      grid-template-columns: repeat(2, 1fr);
  }

  .Projects-box:active .Projects-layer {
    transform: translateY(0);
  }
  
}
@media (max-width: 879px) {
  .Projects-container {
      grid-template-columns: repeat(2, 1fr);
  }
  .Projects-box .Projects-layer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.6), var(--bg-color));
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 0 ;
    transform: translateY(100%);
    transition: .5s ease;
  }

  .Projects-box:active .Projects-layer {
    transform: translateY(0);
  }
}
@media (max-width: 686px) {
  .Projects-container {
      grid-template-columns: 1fr;
  }
}