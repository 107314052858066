.about {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  background: var(--second-bg-color);
}

.about-img {
  width: 300px;
  animation: floatImage 8s ease-in-out infinite;
  filter: drop-shadow(0px 0px 20px var(--main-color-hover));
  transition: .5s ease;
  border-radius: 100px;
  margin-right: 20px;
}

.heading {
  text-align: center;
  font-size: 40px;
}

.about-content h2 {
  text-align: left;
  line-height: 1.2;
}

.about-content h3 {
  font-size: 23px;
}

.about-content p {
  font-size: 15px;
  margin: 2rem 0 3rem;
}

@media (max-width: 768px){
  .about {
    flex-direction: column-reverse;
    gap: 0px;
    align-items: center; /* Center the content on smaller screens */
  }

  .about-img {
    width: 200px;
    margin-top: 40px;
  }

  .about-btn{
    margin-top: 20px;
  }

  .about-content h2 {
    font-size: 25px;
    margin-bottom: 15px;
  }

  .about-content .btna {
    font-size: 10px;
    padding: 15px 25px;
  }

  .about-content h3 {
    font-size: 20px;
  }

  .about-content p {
    font-size: 10px;
    margin-bottom: 15px; /* Adjust margin for better spacing */
  }
}
