.Experiance {
  min-height: auto;
  padding-bottom: 10rem;
}

.Experiance h2 {
  margin-bottom: 3rem;
}

.Experiance-container {
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.Experiance-box {
  background: var(--second-bg-color);
  padding: 30px 20px 40px;
  border-radius: 2rem;
  text-align: center;
  border: .2rem solid var(--bg-color);
  transition: .5s ease;
  margin-bottom: 20px;
}

.Experiance-box:hover {
  border-color: var(--main-color);
  transform: scale(1.02);
}

.Experiance-box i {
  font-size: 70px;
  background: var(--main-gradient-color);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.Experiance-box h3 {
  font-size: 25px;
}

.Experiance-box p {
  font-size: 16px;
  margin: 10px 0 30px;
}

.Experiance-box img{
  margin-top: 40px;
 max-width: 48px;
 margin-left: 30px;
 margin-right: 30px;
 transition: ease 0.5s;
}

.Experiance-box img:hover{
  transform: scale(1.5);
  transition: ease 0.5s;
 }

 @media (max-width: 768px){
  .Experiance h2{
    font-size: 30px;
  }
  
 }