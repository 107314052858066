.home {
  display: flex;
  justify-content: space-between;
}

.img-container.phone {
  display: none;
}

 .img-container{
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers items horizontally */
 }

 .img-container p{
  margin-top: 3rem;
  font-size: 20px;
  text-align: center;
 }

 .home-img img {
  width: 200px;
  animation: floatImage 3s ease-in-out infinite;
  filter: drop-shadow(0px 0px 8px var(--main-color-hover));
  transition: .5s ease;
  align-self: center;
}

.home-img img:hover {
  width: 230px;
  
  filter: drop-shadow(0px 0px 0px var(--main-color-hover));
} 

 @keyframes floatImage {
  0% {
      transform: translateY(0);
  }

  50% {
      transform: translateY(-2.4rem);
  }

  100% {
      transform: translateY(0);
  }
} 

.home-content{
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.home-content h3 {
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}

.home-content h3:nth-of-type(2) {
  margin-bottom: 8rem;
}

.multiple-text {
  background: var(--main-gradient-color);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.home-content h1 {
  font-size: 50px;
  font-weight: 700;
  line-height: 1.3;
}

.home-content p {
  font-size: 16px;
  max-width: 500px;
}

.social-media a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background: transparent;
  border: 3px solid var(--main-color-hover);
  border-radius: 50%;
  font-size: 2rem;
  background: var(--main-gradient-color);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 30px 24px 30px 0;
  transition: .5s ease;
}

.social-media a:hover {
  background: var(--main-gradient-color);
  -webkit-text-fill-color: var(--second-bg-color);
  box-shadow: 0 0 10px var(--main-color);
  transition: .5s ease;
}

.btn {
  display: inline-block;
  padding: 0.9rem 2.4rem ;
  background: var(--main-gradient-color);
  border-radius: 4rem;
  box-shadow: 0 0 10px var(--main-color-hover);
  font-size: 14px;
  color: var(--second-text-color);
  letter-spacing: .1rem;
  font-weight: 600;
  transition: .5s ease;
  margin-bottom: 15px;
}

.btn:hover {
  box-shadow: none;
}
@media (max-width: 1320px) {
  .home-content{
    width: 550px;
  }

  .img-container  {
    width: 300px;
  }
  .home-img img {
    width: 160px;
  }

  .img-container p {
    font-size: 15px;
  }
  .social-media a {
    font-size: 1.7rem; 
    width: 40px;
  height: 40px;
  }
  .home-content h1{
    font-size: 45px; 
  }

  .home-content h3 {
    font-size: 25px; 
  }
}
@media (max-width: 965px) {
  .home {
    flex-direction: column;
    transform: scale(1)
    translateY(-120px);
    }

    .img-container  {
      align-self: center;
      width: 400px;
    }
    .home-img img {
      width: 160px;
    }

  .img-container.phone {
      display: block;
      display: flex;
  flex-direction: column;
  align-items: center; /* Centers items horizontally */
  }

  .img-container.pc {
      display: none;
  }
  
    .home-content p {
      font-size: 16px;
    }
  
    .social-media a {
      width: 40px;
      height: 40px;
      font-size: 25px;
  
    }
    .home-content h3 {
      margin-top: 5rem;
      font-size: 18px;
    }
  
    .home-content h1 {
      
      font-size: 35px;
    }
    .btn{
      margin-bottom: 30px;
      transform: scale(0.8);
      text-align: center;
      margin-left: auto;
      margin-right: auto;
  }
    .home-img img {
      margin-top: 50px;
      width: 150px;
    }
    .home-img img:hover {
      width: 170px;
      filter: drop-shadow(0px 0px 0px var(--main-color-hover));
    }

    .home-content h3:nth-of-type(2) {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
}

@media (max-width: 765px) {
  .home {
    flex-direction: column;
    transform: scale(1)
    translateY(-120px);
    }
    .home-content {
      text-align: center; /* Ensure text is centered */
      width: 100%; /* Take full width of the screen */
      padding: 0 20px; /* Optional: Add padding for better appearance */
    }

    .img-container  {
      width: 300px;
      align-self: center;
      margin-top: 20px;
    }
    .home-img img {
      width: 160px;
    }

  .img-container.phone {
      display: block;
      display: flex;
  flex-direction: column;
  align-items: center; /* Centers items horizontally */
  }

  .img-container.pc {
      display: none;
  }
  
    .home-content p {
      font-size: 16px;
    }
  
    .social-media a {
      width: 35px;
      height: 35px;
      font-size: 25px;
      margin: 25px 15px 25px 0;
    }
    .home-content h3 {
      margin-top: 5rem;
      font-size: 18px;
    }
  
    .home-content h1 {
      
      font-size: 35px;
    }
    .btn{
      margin-bottom: 30px;
      transform: scale(0.8);
      text-align: center;
      margin-left: auto;
      margin-right: auto;
  }
    .home-img img {
      margin-top: 50px;
      width: 150px;
    }
    .home-img img:hover {
      width: 170px;
      filter: drop-shadow(0px 0px 0px var(--main-color-hover));
    }

    .home-content h3:nth-of-type(2) {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
}


@media (max-width: 365px) {
  .home {
    flex-direction: column;
    transform: scale(1)
    translateY(-120px);
    }
    .home-content {
      text-align: center; /* Ensure text is centered */
      width: 100%; /* Take full width of the screen */
      padding: 0 20px; /* Optional: Add padding for better appearance */
    }

    .img-container  {
      width: 250px;
      align-self: center;
      margin-top: 20px;
    }
    .home-img img {
      width: 160px;
    }

  .img-container.phone {
      display: block;
      display: flex;
  flex-direction: column;
  align-items: center; /* Centers items horizontally */
  }

  .img-container.pc {
      display: none;
  }
  
    .home-content p {
      font-size: 12px;
    }
  
    .social-media a {
      width: 35px;
      height: 35px;
      font-size: 25px;
      margin: 25px 10px 25px 0;
    }
    .home-content h3 {
      margin-top: 5rem;
      font-size: 18px;
    }
  
    .home-content h1 {
      
      font-size: 35px;
    }
    .btn{
      margin-bottom: 30px;
      transform: scale(0.8);
      text-align: center;
      margin-left: auto;
      margin-right: auto;
  }
    .home-img img {
      margin-top: 50px;
      width: 120px;
    }
    .home-img img:hover {
      width: 170px;
      filter: drop-shadow(0px 0px 0px var(--main-color-hover));
    }

    .home-content h3:nth-of-type(2) {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
}